<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Penambahan Dana Toko</p>
     <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datapenambahan.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
          <tr>        
             <td class="capitalize">tanggal</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="datapenambahan.tanggal" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">penambahan</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="text" id="penambahan" name="penambahan" class="form-control form-control-sm" placeholder="penambahan" v-model="datapenambahan.penambahan" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">Jumlah Masuk</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="number"  min="1"  id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="datapenambahan.jumlah" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">keterangan</td>
             <td class="pl-2 pr-2">:</td>
             <td>
              <div class="sm-form">
              <textarea
              type="text"
              id="keterangan"
              name="keterangan"
              rows="2"
              placeholder="keterangan..."
              class="form-control md-textarea"
              v-model="datapenambahan.keterangan"
              ></textarea>
              </div>
             </td>
         </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datapenambahan = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class=" col-sm-7 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length>0" class="p-2">
               <div class="text-right">
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
                <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','harga','stok','keluar','status','tanggal_w']" @selected="ambil" ref="btable" />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datapenambahan: {},
      produks:[],
      btn: "tambah",
      stok:0,
      datanya:[],
    };
  },
  methods: {
    format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
     async cekstok(item){
        console.log(this.datapenambahan)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.datapenambahan.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.datapenambahan.diskon=res[0].diskon
         }
          this.datapenambahan.harga=res[0].harga
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.datapenambahan.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_toko_penambahan").doc().get("*",`where id_produk='${this.datapenambahan.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.datapenambahan.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.$forceUpdate();
    },
    getData(){
       sdb.collection("app_kasir_toko_penambahan",false).doc().select(`select * from app_kasir_toko_penambahan`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      // sdb.collection("app_kasir_produk").doc().get("*","order by id").then(res => {
      //     this.produks=res;
      //       this.produks.map(function (x){ // taruh setelah produks di initial
      //           return x.item_data = x.barcode + ' / ' + x.nama_produk;
      //     });
      // });
      
    },
    ambil(data) {
      this.datapenambahan = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    submit() {
      let that=this
      this.datapenambahan.status='masuk'
      this.datapenambahan.tanggal_w=this.format(new Date(this.datapenambahan.tanggal));
      if(this.datapenambahan.id_produk==''){
        alert('Barcode Produk terlebih dahulu')
          return
      }
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datapenambahan
          delete this.datapenambahan.id;
          sdb
            .collection("app_kasir_toko_penambahan")
            .doc()
            .set(this.datapenambahan)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_toko_penambahan")
            .doc()
            .set(this.datapenambahan)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_toko_penambahan")
            .doc(this.datapenambahan.id)
            .delete()
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
    this.datapenambahan.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")

  },
};
</script>